<template>
  <div v-if="ready" class="reorderable-section">
    <DeleteSectionConfirmationModal :is-open="isOpen" @dismissModal="dismiss" @remove="remove" />
    <div class="top-bar d-flex justify-content-between">
      <div class="section-handle">
        <div class="handle d-flex flex-column align-items-center justify-content-center">
          <i class="ti-more-alt top-h" />
          <i class="ti-more-alt bottom-h" />
        </div>
      </div>
      <div class="number text-center" @click="openReorderPopover($event, index + 1)">
        <span v-if="index < 99">{{ leadingZero(index + 1) }}</span>
        <span v-else>{{ index + 1 }}</span>
      </div>
    </div>
    <div v-if="usedKey === 'rooms'">
      <slot />
    </div>
    <div v-else class="content" :class="{ collapsed }">
      <div class="actions mb-3 d-flex">
        <ion-button color="danger" class="delete-btn" @click="_removeSection">
          <i class="mr-2 ti-close" />
          Delete
        </ion-button>
        <ion-button class="collapse-btn" color="medium" @click="collapse">
          <span class="white">{{ action }}</span>
        </ion-button>
      </div>
      <div class="title-bar d-flex justify-content-between">
        <div class="title">{{ title }}</div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ReorderPopover from '../popovers/ReorderPopover.vue';
import ChatroomsReorderableSection from './ChatroomsReorderableSection.vue';
import ReorderChatroomButton from './ReorderChatroomButton.vue';
import { popovers } from '@/shared/native';
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import { leadingZero } from '@/shared/utils/number';

import DeleteSectionConfirmationModal from '@/shared/pages/character-editor/modals/DeleteSectionConfirmationModal.vue';
import { unreorderableCreatorKeys } from '@/shared/statics/constants';
// import Chatrooms from './Chatrooms.vue';

const { removeSection, socialSpace, changeKey } = socialSpaceCreatorStore();

const ready = ref(true);
const po = ref(['dasjkd', 'dlkasdmasd', 'sdsnkald', 'dnklasd']);
const props = defineProps({
  title: {
    type: String,
  },
  usedKey: {
    type: String,
  },
  index: {
    type: Number,
    default: 0,
  },
  value: {
    type: Object,
  },
});

const usedKey = toRef(props, 'usedKey');

const possibleSections = computed(() => {
  return omit(socialSpace?.value, unreorderableCreatorKeys as string[]);
});

const openReorderPopover = async (ev: CustomEvent, from: number) => {
  (
    await popovers.open(
      ev,
      ReorderPopover,
      {
        order: from,
      },
      'reorder-popover'
    )
  )
    .onDidDismiss()
    .then((data) => {
      const to = Number(data);
      const order: string[] = socialSpace?.value?.order || Object.keys(possibleSections.value);
      order.splice(to, 0, order.splice(from, 1)[0]);
      changeKey('order', order);
    });
};

const collapsed = ref(false);
const isOpen = ref(false);

const action = computed(() => {
  return collapsed.value ? 'Expand' : 'Collapse';
});

const _removeSection = () => {
  isOpen.value = true;
};

const dismiss = () => {
  isOpen.value = false;
};

const remove = () => {
  isOpen.value = false;
  nextTick(() => {
    if (!usedKey.value) return;

    removeSection(usedKey.value);

    ready.value = false;
    nextTick(() => {
      ready.value = true;
    });
  });
};

const collapse = () => {
  collapsed.value = !collapsed.value;
};
</script>
<style lang="sass" scoped>
.reorderable-section
  border-radius: 20px
  background: #CCC
  .actions
    grid-gap: 10px
    .collapse-btn, .delete-btn
      text-transform: unset !important
      width: 100%
      --border-radius: 10px
      height: 25px
      color: white !important
  .content
    background: #E6E6E6
    border-radius: 20px
    padding: 20px
    color: #333333
    font-size: 16px
    font-weight: bold
    transition: 300ms ease all
    height: auto
    i
      font-size: 12px
    &.collapsed
      height: 65px
      overflow: hidden !important
      transition: 300ms ease all
    .title
      font-size: 24px
    .title-bar
      margin-bottom: 24px
    .remove-btn
      width: 24px
      height: 24px
      max-width: 24px
      --padding-start: 0
      --padding-end: 0
      margin: 0
  .top-bar
    padding: 9px 20px
    .number
      width: 68px
      color: #000
      font-weight: bold
      background: #fff
      font-size: 20px
      border-radius: 10px
      cursor: pointer
    .handle
      width: 68px
      color: #FFF
      background: #7050B7
      border-radius: 10px
      .top-h
        margin-bottom: -10px
.section-handle
  cursor: move
  -webkit-user-drag: element
</style>
