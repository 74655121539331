<template>
  <div class="social-space-settings position-relative">
    <div>
      <div class="header">
        Mark Social Space as Mature?
        <a
          class="link"
          rel="nofollow"
          target="_blank"
          href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo"
          >(see content guidelines)</a
        >
      </div>
      <ion-radio-group v-model="isNSFW" class="d-flex mt-3 ml-2" mode="md">
        <div @click="() => (changeColor = true)" class="d-flex align-items-center">
          <ion-radio
            :disabled="socialSpace?.privacy === 'M' ? false : !(user.is_nsfw || user.show_nsfw)"
            class="mr-1"
            :value="true"
          />Yes
        </div>
        <div class="d-flex align-items-center ml-5">
          <ion-radio
            :disabled="socialSpace?.privacy === 'M' ? false : !(user.is_nsfw || user.show_nsfw)"
            :value="false"
            class="mt-1 mr-1"
          />No
        </div>
      </ion-radio-group>
      <div
        v-if="socialSpace?.privacy === 'M' ? false : !(user.is_nsfw || user.show_nsfw)"
        class="d-flex align-items-center p-2 my-2"
        style="height: 30px"
        :style="changeColor ? `background: #ffc409 !important` : ''"
      >
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="font-size: 10px; color: grey" :style="changeColor ? `font-weight: bold` : ''">
          You must turn on “show mature content” and confirm you’re 18+ in
          <router-link :style="changeColor ? `font-weight: bold` : ''" to="/account/user-settings" target="_blank">
            <strong class="clickable-item-hov">Content Settings</strong>
          </router-link>
          to share mature content
        </p>
      </div>
    </div>
    <hr class="my-4" />
    <Transition name="slide-fade" mode="out-in">
      <div>
        <div class="d-flex justify-content-between align-items-center clickable-item-hov" @click="actionClicked">
          <div class="header">Advanced Settings</div>
          <div></div>
          <div class="clickable-item-hov">
            <i class="ti-angle-down mr-1 toggle-icon" v-if="isSectionCollapsed" />
            <i class="ti-angle-up mr-1 toggle-icon" v-else />
          </div>
        </div>
        <div class="px-2 mt-3" v-if="!isSectionCollapsed">
          <div class="text">
            <div class="title bold mb-3">Trigger warning</div>
            <BodyOnly
              :key="rerenderKey"
              :value="triggerwarning"
              :is-trigger="true"
              placeholder="If there are no trigger warnings, you can leave this blank"
              used-key="triggerwarning"
            />
          </div>
        </div>
      </div>
    </Transition>
    <hr class="my-4" />

    <div>
      <div class="header">Privacy</div>
      <div v-if="isNSFW && !user.is_nsfw && !user.show_nsfw" class="d-flex align-items-center mt-2">
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="font-size: 10px; color: grey">
          You can not post mature content publicly until you confirm you're 18+ in
          <router-link to="/account/user-settings" target="_blank">
            <strong class="clickable-item-hov">Content Settings</strong>
          </router-link>
        </p>
      </div>
      <ion-radio-group v-model="privacy" class="d-flex flex-column ml-2" mode="md">
        <div class="d-flex align-items-center mt-3">
          <ion-radio value="M" class="mr-1 clickable-item-hov" />
          <div class="d-flex flex-column">
            <div class="bold clickable-item-hov" @click="setPrivacy('M')">Only Me</div>
            <div class="msg">🔒 Only you can see your social space.</div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <ion-radio :disabled="isNSFW && !user.is_nsfw && !user.show_nsfw" value="U" class="mr-1 clickable-item-hov" />
          <div :style="isNSFW && !user.is_nsfw && !user.show_nsfw ? 'color:grey' : ''" class="d-flex flex-column">
            <div
              class="bold clickable-item-hov"
              @click="isNSFW && !user.is_nsfw && !user.show_nsfw ? null : setPrivacy('U')"
            >
              Unlisted
            </div>
            <div class="msg">Only people in the group or with the link can see it and request to join.</div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <ion-radio :disabled="isNSFW && !user.is_nsfw && !user.show_nsfw" value="P" class="mr-1 clickable-item-hov" />
          <div :style="isNSFW && !user.is_nsfw && !user.show_nsfw ? 'color:grey' : ''" class="d-flex flex-column">
            <div
              class="bold clickable-item-hov"
              @click="isNSFW && !user.is_nsfw && !user.show_nsfw ? null : setPrivacy('P')"
            >
              Closed
            </div>
            <div class="msg">Everyone can apply to join. You need to approve them.</div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <ion-radio :disabled="isNSFW && !user.is_nsfw && !user.show_nsfw" class="mr-1 clickable-item-hov" value="O" />
          <div :style="isNSFW && !user.is_nsfw && !user.show_nsfw ? 'color:grey' : ''" class="d-flex flex-column">
            <div
              class="bold clickable-item-hov"
              @click="isNSFW && !user.is_nsfw && !user.show_nsfw ? null : setPrivacy('O')"
            >
              Open
            </div>
            <div class="msg">Anybody can join your social space! No approval needed.</div>
          </div>
        </div>
      </ion-radio-group>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BodyOnly from './BodyOnly.vue';
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import { mainStore } from '@/shared/pinia-store/main';
import { warningOutline } from 'ionicons/icons';
import { authStore } from '@/shared/pinia-store/auth';
const { socialSpace, changeKey, dropdownCollapsed, collapseDropDown } = socialSpaceCreatorStore();

const rerenderKey = ref(0);
const { user } = authStore();
const changeColor = ref(false);

const isDarkMode = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

watch(isDarkMode, () => {
  rerenderKey.value++;
});

const isNSFW = computed({
  get() {
    return socialSpace.value?.is_nsfw || false;
  },
  set(value) {
    changeKey('is_nsfw', value);
  },
});
const actionClicked = () => {
  let tempSectionValue = isSectionCollapsed.value;
  tempSectionValue = !tempSectionValue;
  collapseDropDown(tempSectionValue);
};
const isSectionCollapsed = computed(() => {
  return dropdownCollapsed.value || false;
});

const privacy = computed({
  get() {
    return socialSpace.value?.privacy || 'P';
  },
  set(value) {
    changeKey('privacy', value);
  },
});

const setPrivacy = (value: string) => {
  changeKey('privacy', value);
};

const triggerwarning = computed({
  get() {
    return socialSpace.value?.triggerwarning || '';
  },
  set(value) {
    changeKey('triggerwarning', value);
  },
});
</script>

<style lang="sass" scoped>
.dark .social-space-settings
  background-color: #17074C !important
  color: #FFF !important
.social-space-settings
  background: #E6E6E6
  border-radius: 20px
  padding: 24px
  margin-top: 24px
  color: #333333
  .select
    padding-right: 10px
  .header
    font-weight: bold
  .msg
    color: #333
    font-weight: 300
    font-size: 13px
.clickable-item-hov
  background: unset
</style>
