<template>
  <div class="social-space-actions position-relative">
    <div class="justify-content-center d-flex">
      <ion-button
        v-if="get(user, 'is_email_verified', true)"
        class="btn"
        :disabled="action === 'publish' && loading"
        @click="doPublish"
      >
        <ChLoading size="sm" v-if="action === 'publish' && loading" />
        <div v-else>{{ actionName }}</div>
      </ion-button>
      <VerificationButton v-else />
    </div>
    <span v-if="!isNew" class="d-flex justify-content-center mt-4">
      <span class="delete-btn" @click="doDelete">Delete</span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';
import { useSocialSpace, socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import { useImageStore } from '@/shared/pinia-store/images';
import constants from '@/shared/statics/constants';
import { isYoutubeVideoLinkValid } from '@/shared/utils/string';

import VerificationButton from '@/shared/components/VerificationButton.vue';
import { authStore } from '@/shared/pinia-store/auth';
const { user } = authStore();

const { autosaveEnabled, autosaveNew, autosave, isDirty, socialSpace, saveWithIntent, deleteSocialSpace } =
  socialSpaceCreatorStore();
const stopWatch = ref(null as any);
const stopWatchImg = ref(null as any);
const loading = ref(false);
const action = ref('');
const id = computed(() => socialSpace?.value?.id);
const isNew = computed(() => !id.value);
const emit = defineEmits(['done']);

const spotifyLink = computed(() => {
  return socialSpace.value?.spotify_link;
});

const actionName = computed(() => {
  return 'Publish';
});

const doDelete = async () => {
  loading.value = true;
  action.value = 'delete';
  const alert = await alertController.create({
    cssClass: '',
    header: 'Are you sure?',
    message: `Please confirm that you want to delete this Social Space.`,
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'text-secondary',
        id: 'cancel-button',
      },
      {
        text: 'Confirm',
        id: 'confirm-button',
        role: 'ok',
        cssClass: 'text-secondary',
      },
    ],
  });
  await alert.present();
  const { role } = await alert.onDidDismiss();
  if (role === 'ok') {
    await deleteSocialSpace();
  }
};

const doPublish = async () => {
  loading.value = true;
  action.value = 'publish';
  try {
    if (!socialSpace.value?.name) {
      await toast.show('Social Space name is required', 'nonative', 'danger');
      return;
    }
    if (
      spotifyLink.value &&
      !constants.spotifyLinkRegex.test(spotifyLink.value) &&
      !isYoutubeVideoLinkValid(spotifyLink.value)
    ) {
      await toast.show(
        'Invalid music link. Please enter a Spotify playlist, album or track link, or a Youtube video link.',
        'nonative',
        'danger'
      );
      return;
    }
    await saveWithIntent();
    toast.show('Successfully saved.', 'nonative', 'primary');
  } catch (_err) {
  } finally {
    loading.value = false;
  }
};

const _autosave = async () => {
  if (!autosaveEnabled.value) return;
  if (!isDirty.value) return;
  if (isNew.value) autosaveNew();
  else autosave();
};
const callAutosave = debounce(_autosave, 3000, { leading: false, trailing: true });

onMounted(() => {
  const editorStoreToWatch = useSocialSpace();
  const imageStoreToWatch = useImageStore();
  setTimeout(() => {
    stopWatch.value = watch(() => editorStoreToWatch.$state, callAutosave, { deep: true });
    stopWatchImg.value = watch(() => imageStoreToWatch.$state, callAutosave, { deep: true });
  }, 5000);
});
onBeforeUnmount(() => {
  if (stopWatch.value) stopWatch.value();
  if (stopWatchImg.value) stopWatchImg.value();
});
</script>

<style lang="sass" scoped>
.social-space-actions
  margin-bottom: 80px
  .btn
    --border-radius: 10px
    text-transform: unset
    min-width: 150px
.white
  color: white !important
.delete-btn
  background-color: transparent
  color: gray
  cursor: pointer
  padding: 0 0.5rem 0.5rem 0.5rem

.delete-btn:hover
  text-decoration: underline
</style>
