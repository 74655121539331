<template>
  <div class="content mb-2">
    <div class="d-flex">
      <DeleteSectionConfirmationModal :is-open="isOpen" @dismissModal="dismiss" @remove="remove(index - 1)" />
      <reorder-chatroom-button
        :index="index"
        :total="chatRooms.length"
        :class="{ collapsed }"
        @reorder="handleReorder"
      />
      <div class="flex-grow-1 mx-3">
        <div class="actions mb-3 d-flex">
          <ion-button color="danger" class="delete-btn" @click="_removeSection">
            <i class="mr-2 ti-close" />
            Delete
          </ion-button>
          <ion-button class="collapse-btn" color="medium" @click="collapse">
            <span class="white">{{ action }}</span>
          </ion-button>
        </div>
        <div class="title-bar d-flex justify-content-between">
          <div class="title">{{ title }}</div>
        </div>
      </div>
    </div>
    <div class="chatroom-header position-relative ml-5" :class="{ collapsed }">
      <div class="cover position-relative">
        <img loading="lazy" v-image :src="resizeUpload(room?.banner_img_url, '800w')" class="image" />
        <ion-button class="edit-btn position-absolute btn-reposition" @click="changeCoverPopover($event, index)">
          <i class="ti-pencil" />
        </ion-button>
      </div>
    </div>
    <div class="chatroom-key-info position-relative ml-5" :class="{ collapsed }">
      <div class="title">Key Info</div>
      <div class="one-info">
        <div class="label">Chatroom Name*</div>
        <ion-input
          v-model="roomTitle"
          class="c-input one-input"
          placeholder="Insert cool name people will want to chat in"
        />
        <!-- @input="changeText('title', $event.target.value.slice(0, 80))" -->
      </div>
      <div class="one-info">
        <div class="label">Chatroom Description</div>
        <div class="text mt-2">
          <div class="editor">
            <Editor
              v-model="roomDescription"
              :api-key="`${tinyMCEKey}`"
              :init="{
                height: 350,
                relative_urls: false,
                menu: {
                  insert: {
                    title: 'Insert',
                    items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
                  },
                },
                menubar: 'edit view insert format tools table help',
                plugins:
                  'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
                toolbar:
                  'undo redo | bold italic backcolor |  blocks | fontfamily |  \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
                contextmenu: false,
                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar: 'bold italic underline strikethrough link',
              }"
              placeholder="Share a welcoming script for travelers who might want to chat!"
            />
          </div>
        </div>
      </div>
      <div class="one-info">
        <div class="label">Tags</div>
        <multiselect
          v-model="roomTags"
          tag-placeholder="Select to create a tag"
          placeholder="Press Enter to Add Tags ↩"
          select-label=""
          deselect-label=""
          :show-no-options="false"
          :options="tagList"
          :multiple="true"
          :taggable="true"
          open-direction="bottom"
          :close-on-select="false"
          class="tags"
          @tag="addTag(index, $event)"
        />
      </div>
      <div class="one-info">
        <div class="label mt-4">Privacy Settings</div>
        <ion-list class="bg-transparent">
          <ion-radio-group v-model="roomPrivacy" mode="md">
            <ion-item class="bg-transparent d-flex" lines="none" @click="setPrivacy('public', index)">
              <ion-radio slot="start" value="public" style="margin-inline-end: 15px" class="clickable-item-hov" />
              <div class="privacy-text clickable-item-hov">Make Public (Every Social Space Member Can Join)</div>
            </ion-item>

            <ion-item class="bg-transparent d-flex" lines="none" @click="setPrivacy('private', index)">
              <ion-radio slot="start" value="private" style="margin-inline-end: 15px" class="clickable-item-hov" />
              <div class="privacy-text clickable-item-hov">
                Make Private (Member have to request permission to join)
              </div>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Multiselect from 'vue-multiselect';
import ChatroomCoverPopover from '../popovers/ChatroomCoverPopover.vue';
import ReorderChatroomButton from './ReorderChatroomButton.vue';
import { popovers } from '@/shared/native';
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import DeleteSectionConfirmationModal from '@/shared/pages/character-editor/modals/DeleteSectionConfirmationModal.vue';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  usedKey: {
    type: String,
  },
  title: {
    type: String,
  },
  collapsed: {
    type: Boolean,
  },
});
const emits = defineEmits(['toggleCollapse']);

const { removeSection, removeChatRoomLocal, chatRooms, updateChatroomLocal, reorderChatRoomLocal } =
  socialSpaceCreatorStore();

const ready = ref(true);
const tagList = ref([]);

const {
  public: { tinyMCEKey },
} = useRuntimeConfig();

const collapsed = toRef(props, 'collapsed');
const title = toRef(props, 'title');
const index = toRef(props, 'index');
const usedKey = toRef(props, 'usedKey');
const room = computed(() => {
  return chatRooms.value[index.value];
});

const roomTags = computed({
  get() {
    return chatRooms.value[index.value]?.tags;
  },
  set(val: any) {
    updateChatroomLocal(index.value, { tags: val });
  },
});

const roomTitle = computed({
  get() {
    return chatRooms.value[index.value]?.title;
  },
  set(val: any) {
    updateChatroomLocal(index.value, { title: val });
  },
});

const roomDescription = computed({
  get() {
    return chatRooms.value[index.value]?.description;
  },
  set(val: any) {
    updateChatroomLocal(index.value, { description: val });
  },
});

const roomPrivacy = computed({
  get() {
    return chatRooms.value[index.value]?.privacy;
  },
  set(val: any) {
    updateChatroomLocal(index.value, { privacy: val });
  },
});

const addTag = (index: any, newTag: string) => {
  roomTags.value = [...roomTags.value, newTag];
};

const handleReorder = (ev: CustomEvent) => {
  const { from, to } = ev.detail;
  reorderChatRoomLocal(from, to);
  try {
    ev.detail.complete();
    ev.stopPropagation();
  } catch (e) {}
};

const isOpen = ref(false);

const action = computed(() => {
  return collapsed.value ? 'Expand' : 'Collapse';
});
const changeCoverPopover = async (ev: CustomEvent, index: any) => {
  const popover = await popovers.open(ev, ChatroomCoverPopover, { index }, 'create-popover');
  const res = await popover.onDidDismiss();

  if (!isEmpty(res?.data?.value)) {
    updateChatroomLocal(index, { banner_img_url: res?.data?.value });
  }
};

const _removeSection = () => {
  isOpen.value = true;
};

const setPrivacy = (newPrivacy: string, index: any) => {
  updateChatroomLocal(index, { privacy: newPrivacy });
};

const dismiss = () => {
  isOpen.value = false;
};

const remove = (index: any) => {
  isOpen.value = false;
  nextTick(() => {
    if (chatRooms.value.length > 1 && index !== -1) {
      removeChatRoomLocal(index);
    } else {
      if (!usedKey.value) return;
      removeSection(usedKey.value);
    }
  });
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};

const collapse = () => {
  emits('toggleCollapse');
};
</script>
<style lang="sass" scoped>
.collapsed
  pointer-events: none
.privacy-text
  font-size: 16px
  font-weight: bold
  color: #333333
.add-btn
  margin-left: 32px
  width: calc(100% - 65px) !important
  ::v-deep
    .button
      height: 30px !important
      text-transform: unset !important

.btn-reposition
  top: 13px
  right: 20px
.edit-btn
  width: 46px
  height: 46px
  font-size: 24px
  --background: rgba(10, 9, 58, 0.7)
  --border-radius: 10px
.chatroom-header
  color: black
  border-radius: 20px
  overflow: hidden
  .cover
    height: 250px
    overflow: hidden
    .image
      object-fit: cover
      width: 100%
      height: 100%

.dark .chatroom-key-info
  background-color: #17074C !important
  color: #FFF !important

.dark .chatroom-key-info .label
  color: #FFF !important
.chatroom-key-info
  padding: 25px 7px
  background: #E6E6E6

  .label
    color: #333333
  .tags
    margin-top: 10px
    border-radius: 10px
    overflow: hidden
    ::v-deep
      input
        font-size: 14px !important
  .title
    color: #333333
    font-size: 24px
    font-weight: bold
  .one-info
    margin-top: 20px
  .one-input
    margin-top: 10px
    border-radius: 10px !important
    ::v-deep
      input, textarea
        border: 0 !important
      textarea
        padding: 12px

.reorderable-section
  border-radius: 20px
  background: #CCC
  .actions
    grid-gap: 10px
    .collapse-btn, .delete-btn
      text-transform: unset !important
      width: 100%
      --border-radius: 10px
      height: 25px
      color: white !important
  .content
    background: #E6E6E6
    border-radius: 20px
    padding: 20px
    color: #333333
    font-size: 16px
    font-weight: bold
    transition: 300ms ease all
    height: auto
    i
      font-size: 12px
    &.collapsed
      height: 65px
      overflow: hidden !important
      transition: 300ms ease all
    .title
      font-size: 24px
    .title-bar
      margin-bottom: 24px
    .remove-btn
      width: 24px
      height: 24px
      max-width: 24px
      --padding-start: 0
      --padding-end: 0
      margin: 0
  .top-bar
    padding: 9px 20px
    .number
      width: 68px
      color: #000
      font-weight: bold
      background: #fff
      font-size: 20px
      border-radius: 10px
      cursor: pointer
    .handle
      width: 68px
      color: #FFF
      background: #7050B7
      border-radius: 10px
      .top-h
        margin-bottom: -10px

ion-radio::part(container)
  width: 30px
  height: 30px

  border-radius: 8px
  border: 3px solid #c2b4b4


ion-radio::part(mark)
  background: none
  transition: none
  transform: none
  border-radius: 0


ion-radio.radio-checked::part(container)
  background: var(--ion-color-primary)
  border-color: transparent


ion-radio.radio-checked::part(mark)
  width: 6px
  height: 10px

  border-width: 0px 2px 2px 0px
  border-style: solid
  border-color: #ffffff

  transform: rotate(45deg)
</style>
