<template>
  <div class="text">
    <div class="editor">
      <Editor
        v-model="mutatedValue"
        :api-key="`${tinyMCEKey}`"
        :init="{
          height: 350,
          relative_urls: false,
          menu: {
            insert: {
              title: 'Insert',
              items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
            },
          },
          menubar: 'edit view insert format tools table help',
          plugins:
            'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
          toolbar:
            'undo redo | bold italic backcolor |  blocks | fontfamily |  \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
          contextmenu: false,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar: 'bold italic underline strikethrough link',
        }"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';

const { changeKey } = socialSpaceCreatorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Body',
  },
  isTrigger: {
    type: Boolean,
    default: false,
  },
});

const mutatedValue = ref('');

const {
  public: { tinyMCEKey },
} = useRuntimeConfig();

const usedKey = toRef(props, 'usedKey');
const value = toRef(props, 'value');
const isTrigger = toRef(props, 'isTrigger');
onMounted(() => {
  mutatedValue.value = value.value;
});
watch(value, (newVal, oldVal) => {
  if (isTrigger.value) {
    mutatedValue.value = newVal;
  }
});
watch(mutatedValue, (newVal, oldVal) => {
  changeKey(usedKey.value, newVal);
});
</script>

<style lang="sass" scoped>
.text
  .editor
    min-height: 200px !important
    ::v-deep
      .tox
        min-height: 200px !important
        border: 0
        border-radius: 10px
</style>
