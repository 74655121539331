<template>
  <div class="overall">
    <div class="socialspace-key-info mb-4 position-relative">
      <div class="title">Key Info</div>
      <div class="one-info">
        <div class="label">Social Space Name*</div>
        <ion-input v-model="name" class="c-input one-input" placeholder="Come up with something clever! No pressure." />
      </div>
      <div class="one-info">
        <div class="label">Quote</div>
        <ion-textarea
          v-model="quote"
          class="c-textarea one-input"
          placeholder="What saying would be on the sign at the entrance of your social space?"
        />
      </div>
      <div class="one-info">
        <div class="label">Tags</div>
        <multiselect
          v-model="tags"
          tag-placeholder="Select to create a tag"
          placeholder="Press Enter to Add Tags ↩"
          select-label=""
          deselect-label=""
          :show-no-options="false"
          :options="tagList"
          :multiple="true"
          :taggable="true"
          :loading="areTagsLoading"
          open-direction="bottom"
          :close-on-select="false"
          class="tags"
          @tag="addTag"
          @search-change="doGetTagsDebounced"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Multiselect from 'vue-multiselect';
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';

import { getTags } from '@/shared/actions/tags';

const { changeKey, socialSpace } = socialSpaceCreatorStore();

const tagList = ref([] as any[]);
const areTagsLoading = ref(false);

const name = computed({
  get() {
    return socialSpace?.value?.name || '';
  },
  set(value) {
    changeKey('name', value);
  },
});

const quote = computed({
  get() {
    return socialSpace?.value?.extra.quote || '';
  },
  set(value) {
    const change = {
      ...socialSpace.value?.extra,
      quote: value,
    };
    changeKey('extra', change);
  },
});

const tags = computed({
  get() {
    return socialSpace?.value?.tags || [];
  },
  set(value) {
    changeKey('tags', value);
  },
});

const addTag = (newTag: any) => {
  const tags = socialSpace.value?.tags || [];
  tags.push(newTag);
  changeKey('tags', tags);
};

const doGetTags = async (search?: string) => {
  areTagsLoading.value = true;

  if (!search) {
    tagList.value = [];
  } else {
    const resp = await getTags(1, 4, search);

    tagList.value = map(resp.results, (tag) => tag.name);
  }

  areTagsLoading.value = false;
};
const doGetTagsDebounced = debounce(doGetTags, 500);
</script>

<style lang="sass" scoped>
.dark .socialspace-key-info
  background-color: #17074C !important
  color: #FFF !important

.dark .socialspace-key-info .label
  color: #FFF !important
.socialspace-key-info
  padding: 40px 20px
  background: #E6E6E6
  border-radius: 20px
  .label
    color: #333333
  .tags
    margin-top: 10px
    border-radius: 10px
    ::v-deep
      input
        font-size: 14px !important
  .title
    color: #333333
    font-size: 24px
    font-weight: bold
  .one-info
    margin-top: 20px
  .one-input
    margin-top: 10px
    border-radius: 10px !important
    ::v-deep
      input, textarea
        border: 0 !important
      textarea
        padding: 12px
</style>
