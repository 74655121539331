<template>
  <div>
    <UploadForm
      ref="uploader"
      class="d-none"
      @loading="loadingCoverChanged"
      @uploaded="handleChangeCover"
      @dismiss="dismiss"
    />
    <div v-if="loadingCover" class="loading d-flex flex-column align-center">
      <div class="px-3 py-2">Uploading</div>
      <ion-progress-bar type="indeterminate"></ion-progress-bar>
    </div>
    <UploadForm
      only-blob-url
      class="cover-uploader"
      :class="{ hide: loadingCover }"
      @loading="loadingCoverChanged"
      @uploaded="handleChangeCover"
      @dismiss="dismiss"
    >
      <ion-item class="item-container pointer">
        <div class="d-flex">
          <i class="ti-layout-grid4-alt" />
          <div class="ml-2 menu-item d-flex align-items-center">Choose a photo</div>
        </div>
      </ion-item>
    </UploadForm>
    <ion-item v-if="cover" class="item-container d-flex pointer" @click="clearCover">
      <i class="ti-eraser" />
      <div class="ml-2 menu-item d-flex align-items-center">Clear Cover Picture</div>
    </ion-item>

    <crop-image-modal
      :image-url="cropImage"
      :is-open="isOpen"
      outline-style="cover"
      @dismiss-modal="closeCropPreview"
      @crop-done="cropCoverPicture"
    />
  </div>
</template>

<script lang="ts" setup>
import UploadForm from '@/shared/components/upload-form.vue';
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import CropImageModal from '@/shared/modals/CropImageModal.vue';
import { upload } from '@/shared/services/upload';

const props = defineProps({
  instance: {
    type: Object,
  },
});

const instance = props.instance;
const isOpen = ref(false);
const cropImage = ref('');
const { socialSpace, changeCover } = socialSpaceCreatorStore();
const loadingCover = ref(false);
const uploader = ref(null);

const handleChangeCover = (url: any) => {
  if (typeof url === 'string') {
    if (url.startsWith('blob')) {
      openCropPreview(url);
    } else {
      changeCover(url);
      isOpen.value = false;
      cropImage.value = '';
      dismiss();
    }
  }
};

const openCropPreview = (imgUrl?: string) => {
  if (!imgUrl) return;
  cropImage.value = imgUrl;
  isOpen.value = true;
};

const closeCropPreview = async () => {
  if (cover.value.includes('blob')) {
    const blob = await fetch(cover.value).then((r) => r.blob());
    const file = new File([blob], 'cropped.jpg', { type: 'image/jpg' });
    const url = await (uploader.value as any).uploadFile(file);
    changeCover(url);
  }
  isOpen.value = false;
  cropImage.value = '';
  dismiss();
};

const cover = computed(() => {
  return socialSpace.value?.cover_image || '';
});

const loadingCoverChanged = (loading: boolean) => {
  loadingCover.value = loading;
};

const cropCoverPicture = async (image: Blob) => {
  const url = await upload(image);

  if (url) changeCover(url);

  isOpen.value = false;
  cropImage.value = '';
};

const clearCover = () => {
  changeCover('');
  dismiss();
};

const dismiss = () => {
  if (instance) {
    instance.dismiss();
  }
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer
.bottom-button
  background-color: var(--ion-color-primary) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
