<template>
  <div>
    <div v-if="ready" class="text world-editor-select">
      <ion-select
        v-model="mutatedIds"
        mode="md"
        placeholder="Add Characters that are part of this World"
        :multiple="true"
        class="bg-white select pr-2"
      >
        <ion-select-option v-for="character in allCharacters" :key="character.id" :value="character.id"
          >{{ character.info.name }}
        </ion-select-option>
      </ion-select>
    </div>
    <div>
      <Sortable
        v-if="ready"
        id="reorder-characters"
        :disabled="true"
        :list="mutatedCharacters"
        :options="sortableOptions"
        item-key="id"
        tag="div"
        @end="handleReorder"
      >
        <template #item="{ element, index }">
          <div :key="element.id" class="d-flex align-items-center mt-2 one-row">
            <div class="locations-card px-2 py-2">
              {{ get(element, 'info.name', '') || name(element) }}
            </div>

            <ion-button class="inline-button" color="transparent" @click="deleteField(index)">
              <i class="ti-trash" />
            </ion-button>
          </div>
        </template>
      </Sortable>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import { getUserCharacters } from '@/shared/actions/characters';
import { authStore } from '@/shared/pinia-store/auth';
import { c } from 'vitest/dist/reporters-5f784f42';

const { user } = authStore();

const { changeKey, socialSpace } = socialSpaceCreatorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: [],
  },
});
const sortableOptions = {
  handle: '.character-handle',
  animation: 150,
};

const ready = ref(true);
const usedKey = toRef(props, 'usedKey');

const name = (element: any) => {
  if (!isEmpty(mutatedCharacters.value)) {
    const data = allCharacters.value.find((el: any) => el.id === element);
    return get(data, 'info.name', '');
  }
};

const allCharacters = ref([] as any[]);

const handleReorder = (e: any) => {
  mutatedCharacters.value.splice(e.newIndex, 0, mutatedCharacters.value.splice(e.oldIndex, 1)[0]);
  mutate();
};

const mutate = () => {
  const characters = mutatedCharacters.value.filter((field: any) => field);
  changeKey(usedKey.value, characters);

  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};

const mutatedIds = computed({
  get() {
    return mutatedCharacters.value!.map((el: any) => el.id);
  },
  set(val) {
    if (val.length > 0) {
      val.forEach((id: any) => {
        const data = allCharacters.value.find((el: any) => el.id === id);
        if (!mutatedCharacters.value.find((el: any) => el.id === id)) {
          mutatedCharacters.value.push(data);
        }
      });
    }
    mutatedCharacters.value = mutatedCharacters.value.filter(
      (el: any) => val.includes(el.id) || (el.user !== user.value.username && el.user !== user.value.id)
    );
    changeKey(usedKey.value, mutatedCharacters.value);
    mutate();
  },
});

const fetchCharacters = async () => {
  try {
    const response = await getUserCharacters(user.value.id, null);
    allCharacters.value = response;
  } catch (_e) {}
};

onMounted(async () => {
  await fetchCharacters();

  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const mutatedCharacters = computed({
  get() {
    return socialSpace.value?.character_worlds as any[];
  },
  set(val) {
    changeKey('character_worlds', val);
  },
});

const deleteField = (index: number) => {
  mutatedCharacters.value?.splice(index, 1);
  mutate();
};
</script>

<style lang="sass" scoped>
.characters-card
  overflow: unset
  border-radius: 10px
  background: #f2f2f2
  color: #45b6f5
  // width: calc(100% - 53px)
  width: calc(100% - 6px)
.dark .characters-card
  background: #11022A

.select
  border-radius: 10px
  padding: 0 10px
  font-weight: normal
</style>
