<template>
  <div :class="{ collapsed }">
    <ChatRoomEditor
      v-for="(item, index) of chatRooms"
      :key="item.id || index"
      :index="index"
      :used-key="usedKey"
      :collapsed="collapsed"
      @toggleCollapse="toggleCollapse"
    />
    <ion-custom-button class="add-btn text-white w-100 py-3" @click.self="addChatRoom">Add Chatroom</ion-custom-button>
  </div>
</template>

<script lang="ts" setup>
import ChatRoomEditor from './ChatRoomEditor.vue';
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';

const { addChatRoom, chatRooms } = socialSpaceCreatorStore();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  usedKey: {
    type: String,
    default: '',
  },
});
const usedKey = toRef(props, 'usedKey');
const collapsed = ref(false);

const toggleCollapse = () => {
  collapsed.value = !collapsed.value;
};
</script>

<style lang="sass" scoped>

.privacy-text
  font-size: 16px
  font-weight: bold
  color: #333333
.add-btn
  margin-left: 32px
  width: calc(100% - 65px) !important
  ::v-deep
    .button
      height: 30px !important
      text-transform: unset !important

.btn-reposition
  top: 13px
  right: 20px
.edit-btn
  width: 46px
  height: 46px
  font-size: 24px
  --background: rgba(10, 9, 58, 0.7)
  --border-radius: 10px
.chatroom-header
  color: black
  border-radius: 20px
  overflow: hidden
  .cover
    height: 250px
    overflow: hidden
    .image
      object-fit: cover
      width: 100%
      height: 100%

.dark .chatroom-key-info
  background-color: #17074C !important
  color: #FFF !important

.dark .chatroom-key-info .label
  color: #FFF !important
.chatroom-key-info
  padding: 25px 7px
  background: #E6E6E6

  .label
    color: #333333
  .tags
    margin-top: 10px
    border-radius: 10px
    overflow: hidden
    ::v-deep
      input
        font-size: 14px !important
  .title
    color: #333333
    font-size: 24px
    font-weight: bold
  .one-info
    margin-top: 20px
  .one-input
    margin-top: 10px
    border-radius: 10px !important
    ::v-deep
      input, textarea
        border: 0 !important
      textarea
        padding: 12px
.collapsed
  height: 120px
  overflow: hidden !important
  transition: 300ms ease all
  border-radius: 20px
.reorderable-section
  border-radius: 20px
  background: #CCC
  .actions
    grid-gap: 10px
    .collapse-btn, .delete-btn
      text-transform: unset !important
      width: 100%
      --border-radius: 10px
      height: 25px
      color: white !important
  .content
    background: #E6E6E6
    border-radius: 20px
    padding: 20px
    color: #333333
    font-size: 16px
    font-weight: bold
    transition: 300ms ease all
    height: auto
    i
      font-size: 12px
    .title
      font-size: 24px
    .title-bar
      margin-bottom: 24px
    .remove-btn
      width: 24px
      height: 24px
      max-width: 24px
      --padding-start: 0
      --padding-end: 0
      margin: 0
  .top-bar
    padding: 9px 20px
    .number
      width: 68px
      color: #000
      font-weight: bold
      background: #fff
      font-size: 20px
      border-radius: 10px
      cursor: pointer
    .handle
      width: 68px
      color: #FFF
      background: #7050B7
      border-radius: 10px
      .top-h
        margin-bottom: -10px

ion-radio::part(container)
  width: 30px
  height: 30px

  border-radius: 8px
  border: 3px solid #c2b4b4


ion-radio::part(mark)
  background: none
  transition: none
  transform: none
  border-radius: 0


ion-radio.radio-checked::part(container)
  background: var(--ion-color-primary)
  border-color: transparent


ion-radio.radio-checked::part(mark)
  width: 6px
  height: 10px

  border-width: 0px 2px 2px 0px
  border-style: solid
  border-color: #ffffff

  transform: rotate(45deg)
</style>
